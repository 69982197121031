import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../data/pages/patterns.yml';
import mobileNavigationPreviewData from '../../../data/previews/mobile-navigation.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { stylesSelect: 'icononly', typesSelect: 'back' };
  }

  handleStylesChange = () => {
    this.setState({
      stylesSelect:
        this.state.stylesSelect === 'icononly' ? 'label' : 'icononly',
    });
  };

  handleTypesChange = () => {
    this.setState({
      typesSelect: this.state.typesSelect === 'back' ? 'close' : 'back',
    });
  };

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Mobile Navigation"
        subnav="patterns">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Mobile Navigation"
          hideHeader
        />
        <PageNavigation>
          <PageNavigationLink>Style</PageNavigationLink>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
        </PageNavigation>

        <Section title="Style">
          <ComponentPreview
            name="StylesContainer"
            hideEnvironmentToggle
            previewData={mobileNavigationPreviewData.styles}
            onChange={this.handleStylesChange}>
            {this.state.stylesSelect === 'icononly' && (
              <img
                width="470"
                src={require('../../../imgs/previews/preview-mobilenavigation-style-icon-only.jpg')}
              />
            )}
            {this.state.stylesSelect === 'label' && (
              <img
                width="470"
                src={require('../../../imgs/previews/preview-mobilenavigation-style-label.jpg')}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="Type">
          <ComponentPreview
            name="TypesContainer"
            hideEnvironmentToggle
            previewData={mobileNavigationPreviewData.types}
            onChange={this.handleTypesChange}>
            {this.state.typesSelect === 'back' && (
              <img
                width="470"
                src={require('../../../imgs/previews/preview-mobilenavigation-type-back.jpg')}
              />
            )}
            {this.state.typesSelect === 'close' && (
              <img
                width="470"
                src={require('../../../imgs/previews/preview-mobilenavigation-type-close.jpg')}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Navigating Back</SectionSubhead>
          <Paragraph>
            The option to go back <strong>in a series of screens</strong> should
            only ever be represented by the arrow icon. We choose not to use the
            back label in case it runs into the current title’s screen.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="spell out “back” and clutter the nav bar."
              img="mobilenav-back-dont"
            />
            <DontDoItem
              type="do"
              text="use the back icon for the option to revisit the last screen."
              img="mobilenav-back-do"
            />
          </DontDo>
          <Paragraph>
            We also don’t label the previous screen because they were just
            there! No need to remind them already.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="distract from the current screen’s title and purpose."
              img="mobilenav-previousscreen-dont"
            />
            <DontDoItem
              type="do"
              text="use only the icon for clarity and consistency across screens."
              img="mobilenav-previousscreen-do"
            />
          </DontDo>

          <SectionSubhead>Reversing Course</SectionSubhead>
          <Paragraph>
            Whether the user is going back, closing a one-off task or canceling
            changes,{' '}
            <strong>
              the option to reverse course should always live in the top left
            </strong>
            . Save the top right for moving forward, whatever the context.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="confirm or move forward anywhere but the top right."
              img="mobilenav-closeforward-dont"
            />
            <DontDoItem
              type="do"
              text="put Close or Cancel in place of the back arrow when appropriate."
              img="mobilenav-closeforward-do"
            />
          </DontDo>
          <Paragraph>
            <em>
              Note that all guidelines pertaining to “going back” apply only to
              Hudl’s iOS app. Android has a built-in back button that removes
              the need to include the option in-app.
            </em>
          </Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
